export default function IllustrationGeneral(): JSX.Element {
  return (
    <svg
      width="155"
      height="60"
      viewBox="0 0 155 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 70.0488L4.54101 67.9837L6.60511 63.4404L8.66921 67.9837L13.2102 70.0488L8.66921 72.1139L6.60511 76.6572L4.54101 72.1139L0 70.0488Z"
        fill="#FFEFAD"
      />
      <path
        d="M143.551 5.28662L145.17 15.1213L155 16.7412L145.17 18.3611L143.551 28.1957L141.932 18.3611L132.102 16.7412L141.932 15.1213L143.551 5.28662Z"
        fill="#FFEFAD"
      />
      <path
        d="M75.8569 42.597C75.8582 40.0575 78.8162 38.6671 80.7708 40.2873L128.647 79.9739C130.586 81.5812 129.801 84.7193 127.333 85.224L102.079 90.3897C101.611 90.4853 101.173 90.6913 100.801 90.9904L80.7046 107.142C78.7411 108.72 75.8255 107.321 75.8267 104.802L75.8569 42.597Z"
        fill="url(#paint0_linear_115_6717)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.6799 23.2578C38.2617 23.2578 34.6799 26.8395 34.6799 31.2578V81.7905C34.6799 86.2088 38.2617 89.7905 42.6799 89.7905H75.834L75.8569 42.597C75.8582 40.0574 78.8162 38.667 80.7708 40.2872L117.657 70.864V31.2578C117.657 26.8395 114.076 23.2578 109.657 23.2578H42.6799Z"
        fill="url(#paint1_linear_115_6717)"
      />
      <path
        d="M72.9387 35.6734C72.9344 34.8269 73.9178 34.3579 74.5724 34.8943L139.662 88.2391C140.346 88.7994 140.02 89.9065 139.141 90.0065L102.191 94.2135C101.969 94.2388 101.763 94.3374 101.603 94.4938L75.0683 120.562C74.4376 121.182 73.3725 120.738 73.3679 119.854L72.9387 35.6734Z"
        fill="url(#paint2_linear_115_6717)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_115_6717"
          x1="75.86"
          y1="36.2166"
          x2="127.408"
          y2="134.811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0A16" />
          <stop offset="0.942708" stopColor="#201252" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_115_6717"
          x1="150.6"
          y1="130.176"
          x2="54.6431"
          y2="38.012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#d83c38" />
          <stop offset="1" stopColor="#F47FAE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_115_6717"
          x1="75.3106"
          y1="35.4994"
          x2="137.033"
          y2="151.965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#201252" />
          <stop offset="0.942708" stopColor="#0F0530" />
        </linearGradient>
      </defs>
    </svg>
  );
}
