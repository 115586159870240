import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Rate = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path d="M7.49352 6.11236C7.48493 6.13464 7.48052 6.15842 7.48052 6.18242V11.2399C7.48052 11.3441 7.56192 11.4286 7.66234 11.4286H10.3384C10.8248 11.4286 11.2789 11.1764 11.5487 10.7565L12.3271 9.54493C12.4864 9.297 12.5714 9.0057 12.5714 8.70772V6.90073C12.5714 6.48396 12.2458 6.14609 11.8442 6.14609H9.2987L9.49266 5.09939C9.59665 4.53815 9.38621 3.9641 8.94903 3.61646L8.76177 3.46755C8.66619 3.39155 8.52709 3.43121 8.48237 3.54723L7.49352 6.11236ZM4.93507 6.14609C4.73423 6.14609 4.57143 6.31502 4.57143 6.52341V11.0512C4.57143 11.2596 4.73423 11.4286 4.93507 11.4286H6.38961C6.59044 11.4286 6.75325 11.2596 6.75325 11.0512V6.52341C6.75325 6.31502 6.59044 6.14609 6.38961 6.14609H4.93507Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.58172 0 0 3.58172 0 8C0 9.69516 0.528147 11.2692 1.42857 12.5636V15.1429C1.42857 15.4484 1.59122 15.7308 1.85549 15.8842C2.11976 16.0376 2.44569 16.0387 2.71098 15.8871L4.19528 15.0389C5.32739 15.652 6.62407 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM1.71429 8C1.71429 4.5285 4.5285 1.71429 8 1.71429C11.4715 1.71429 14.2857 4.5285 14.2857 8C14.2857 11.4715 11.4715 14.2857 8 14.2857C6.76966 14.2857 5.62429 13.9331 4.65641 13.3238C4.38847 13.1551 4.04938 13.1478 3.77449 13.3049L3.14286 13.6658V12.2864C3.14286 12.1007 3.08254 11.92 2.97099 11.7715C2.18168 10.721 1.71429 9.41631 1.71429 8Z"
    />
  </svg>
);

export { Rate };
