import { AppIconTiny } from "@/client/assets/icons/AppIconTiny";
import Button from "../Button";
import { Help } from "@/client/assets/icons/Help";
import { HomeProps } from "@/application/module/home/home.model";
import { Rate } from "@/client/assets/icons/Rate";
import { Tools } from "@/client/assets/icons/Tools";
import styled from "styled-components";
import { surfaceAppSdkService } from "@/lib/pipedriveSurface";
import { useSWRConfig } from "swr";
import { useTranslation } from "next-i18next";

const StyledMainFooter = styled.footer<{ isDev: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: ${({ isDev }) => (isDev ? "inherit" : "fixed")};
  bottom: 0px;
  padding: 0.75rem 1rem 0.25rem 1rem;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  font-size: 12px;
  box-shadow: ${(props) => props.theme.shadows.footer};
  border-top: 1px solid ${(props) => props.theme.colors.divider.default};
  background-color: ${(props) => props.theme.colors.fill.white};

  * > a {
    color: ${(props) => props.theme.colors.text.linkInverted};
    display: inline-flex;
  }

  a:hover,
  a:focus {
    color: ${(props) => props.theme.colors.text.linkInverted};
    text-decoration: none;
  }
`;

const StyledSticker = styled.button`
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  color: ${(props) => props.theme.colors.text.primaryInverted};
  background-color: ${(props) => props.theme.colors.primary.default};

  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  border: 2px solid ${(props) => props.theme.colors.primary.default};
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.hover};
    border: 2px solid ${(props) => props.theme.colors.primary.hover};
  }

  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors.primary.strong};
    border: 2px solid ${(props) => props.theme.colors.primary.default};
  }
`;

const StyledWrapper = styled.div<{ gap: number }>`
  display: inline-flex;
  align-items: center;
  gap: ${(props) => props.gap}rem;
`;

type Props = {
  customFooter: HomeProps["customFooter"];
  isDev: boolean;
  isMaybeAdmin: boolean;
};
export default function MainFooter({
  customFooter,
  isDev,
  isMaybeAdmin,
}: Props): JSX.Element {
  const { mutate } = useSWRConfig();

  const { t } = useTranslation(["main"]);

  const handleOpenSettings = () => {
    surfaceAppSdkService.openSettingsModal(() => {
      mutate("domains");
    });
  };

  const customFooterFields = {
    help_link: customFooter?.help_link || "https://help.theoneclick.io",
    logo_link: customFooter?.logo_link || "https://www.zimple.io",
    logo_url: customFooter?.logo_url || null,
    share_feedback_email: customFooter?.share_feedback_email || "",
  } as const;

  const handleOpenShareFeedback = () => {
    surfaceAppSdkService.openShareFeedbackModal();
  };

  const openInNewTab = (url?: string | null): void => {
    if (!url) return;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const onClickUrl =
    (url: string): (() => void) =>
    () =>
      openInNewTab(url);

  return (
    <StyledMainFooter isDev={isDev} data-cy="main_footer">
      <StyledWrapper gap={0.25}>
        {customFooterFields.logo_url ? (
          <div>
            {/*eslint-disable-next-line @next/next/no-img-element*/}
            <StyledImage
              data-cy="customer_footer_image"
              alt="custom-footer-img"
              hasLink={Boolean(customFooterFields?.logo_link)}
              src={customFooterFields.logo_url}
              onClick={() => openInNewTab(customFooterFields?.logo_link)}
            />
          </div>
        ) : (
          <StyledSticker
            onClick={onClickUrl(customFooterFields.logo_link)}
            data-cy="default_footer_logo"
          >
            <AppIconTiny />
            <span
              data-cy="default_footer_span_tag"
              style={{ color: "#ffffff" }}
            >
              {t("main.powered_by_zimple")}&nbsp;
              <a
                data-cy="default_footer_a_tag"
                target={"_blank"}
                href={customFooterFields.logo_link}
                rel="noreferrer"
              >
                {t("main.powered_by_zimple_link")}
              </a>
            </span>
          </StyledSticker>
        )}
      </StyledWrapper>

      <StyledWrapper gap={0.25}>
        <Button
          data-cy="footer_share_feedback_button"
          onClick={handleOpenShareFeedback}
          variant="ghost"
          icon="iconOnly"
        >
          <Rate />
        </Button>

        <Button
          data-cy="footer_share_help_button"
          onClick={onClickUrl(customFooterFields.help_link)}
          variant="ghost"
          icon="iconOnly"
        >
          <Help />
        </Button>

        {isMaybeAdmin && (
          <Button
            data-cy="footer_settings_button"
            onClick={() => handleOpenSettings()}
            icon="left"
          >
            <Tools />
            {t("main.open_settings")}
          </Button>
        )}
      </StyledWrapper>
    </StyledMainFooter>
  );
}

const StyledImage = styled.img<{ hasLink: boolean }>`
  max-width: 160px;
  max-height: 32px;
  cursor: ${({ hasLink }) => (hasLink ? "pointer" : "default")};
`;
