import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const AppIconTiny = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 3.00469C0 1.34525 1.34525 0 3.00469 0H12.9953C14.6548 0 16 1.34525 16 3.00469V12.9953C16 14.6548 14.6548 16 12.9953 16H3.00469C1.34525 16 0 14.6548 0 12.9953V3.00469Z"
      fill="#ED6FA2"
    />
    <path
      d="M4.28105 3.18026C4.27555 3.14895 4.309 3.1254 4.33662 3.14113L13.4863 8.35096C13.5152 8.36738 13.5101 8.41043 13.4782 8.41967L8.95044 9.73149C8.94239 9.73382 8.93535 9.73877 8.93045 9.74556L6.16916 13.5661C6.14971 13.5931 6.10747 13.5833 6.10173 13.5506L4.28105 3.18026Z"
      fill="#1C1145"
    />
  </svg>
);

export { AppIconTiny };
