/**
 * Returns if application is displayed in a IFrame
 * @returns {Boolean}
 */
export function isInIFrame(): boolean {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
}
