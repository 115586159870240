import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Help = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      {...(props.fill ? { fill: props.fill } : undefined)}
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 11.5C7.58579 11.5 7.25 11.8358 7.25 12.25V12.75C7.25 13.1642 7.58579 13.5 8 13.5C8.41421 13.5 8.75 13.1642 8.75 12.75V12.25C8.75 11.8358 8.41421 11.5 8 11.5ZM11.0026 6.99372C11.2486 6.39986 11.313 5.7464 11.1876 5.11596C11.0621 4.48552 10.7526 3.90642 10.2981 3.4519C9.84358 2.99738 9.26448 2.68785 8.63404 2.56245C8.0036 2.43705 7.35014 2.50141 6.75628 2.74739C6.16242 2.99338 5.65484 3.40994 5.29772 3.9444C4.94061 4.47886 4.75 5.10721 4.75 5.75C4.75 6.16421 5.08579 6.5 5.5 6.5C5.91421 6.5 6.25 6.16421 6.25 5.75C6.25 5.40388 6.35263 5.06554 6.54493 4.77775C6.73722 4.48997 7.01053 4.26567 7.3303 4.13321C7.65007 4.00076 8.00194 3.9661 8.34141 4.03363C8.68087 4.10115 8.99269 4.26782 9.23744 4.51256C9.48218 4.75731 9.64885 5.06913 9.71637 5.40859C9.7839 5.74806 9.74924 6.09993 9.61679 6.4197C9.48433 6.73947 9.26003 7.01278 8.97225 7.20507C8.88004 7.26668 8.78266 7.31908 8.68149 7.36185C8.0637 7.62305 7.25 8.2481 7.25 9.25V9.75C7.25 10.1642 7.58578 10.5 8 10.5C8.41421 10.5 8.75 10.1642 8.75 9.75V9.25C8.75 9.19701 8.76977 9.12135 8.85796 9.02158C8.9499 8.91757 9.09395 8.81603 9.26562 8.74345C9.45351 8.66401 9.63438 8.56668 9.8056 8.45228C10.3401 8.09516 10.7566 7.58758 11.0026 6.99372Z"
    />
  </svg>
);

export { Help };
