import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Tools = (props: Props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.5889 1.22991C11.8237 1.30463 11.8801 1.59891 11.7059 1.77323L9.30658 4.17348L9.75127 6.24979L11.8268 6.69473L14.2261 4.29447C14.4004 4.12016 14.6945 4.1766 14.7692 4.41153C15.3068 6.10236 14.9052 8.02724 13.5646 9.36841C12.1526 10.781 10.0934 11.1514 8.34193 10.4796L4.40682 14.4163C3.62746 15.196 2.36387 15.196 1.58452 14.4163C0.80515 13.6366 0.805163 12.3725 1.58454 11.5928L5.52126 7.65471C4.85242 5.90366 5.22332 3.84619 6.63396 2.43498C7.97459 1.09381 9.89871 0.692122 11.5889 1.22991Z" />
  </svg>
);

export { Tools };
