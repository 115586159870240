type Props = {
  dataCy?: string;
};

export default function IllustrationError(props: Props): JSX.Element {
  return (
    <svg
      data-cy={props.dataCy}
      width="133"
      height="60"
      viewBox="0 0 133 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60.9993" cy="12" r="12" fill="#CDC4EE" />
      <path
        d="M68.0314 41.2943C68.8757 41.2364 69.4057 42.1881 68.912 42.8754L30.5094 96.3368C29.9936 97.0548 28.8682 96.7994 28.7128 95.9291L23.6249 67.436C23.5857 67.2164 23.4742 67.0163 23.3082 66.8673L1.76182 47.5412C1.1037 46.9509 1.47911 45.8596 2.3611 45.7991L68.0314 41.2943Z"
        fill="url(#paint0_linear_115_6698)"
      />
      <path
        d="M116.114 20.4574C121.073 22.9802 124.375 26.2938 126.021 30.3982C127.714 34.5262 127.357 38.954 124.952 43.6814C122.736 48.0381 119.566 50.7137 115.443 51.7084C111.39 52.6802 107.011 51.9987 102.306 49.6637L99.3063 54.701L88.8781 49.396L95.1086 36.2882L99.2799 38.4102C102.849 40.2256 105.784 41.1646 108.086 41.2271C110.434 41.3132 112.198 40.1975 113.376 37.8802C114.202 36.258 114.41 34.7594 114.001 33.3845C113.592 32.0096 112.599 30.9213 111.023 30.1197C109.355 29.2709 107.81 29.0974 106.388 29.5991C104.989 30.0545 103.877 31.0933 103.052 32.7155L91.859 27.0215C93.2045 24.1472 95.0806 21.8927 97.4873 20.2583C99.9404 18.6474 102.748 17.8297 105.911 17.8052C109.144 17.7579 112.545 18.6419 116.114 20.4574ZM85.036 70.1948C82.9504 69.1338 81.542 67.6589 80.811 65.7701C80.1498 63.8586 80.279 61.999 81.1985 60.1915C82.1416 58.3376 83.5803 57.115 85.5147 56.5238C87.4953 55.9561 89.5285 56.2028 91.6141 57.2638C93.6534 58.3012 95.0036 59.7757 95.6648 61.6873C96.3723 63.6224 96.2545 65.5169 95.3114 67.3708C94.3919 69.1784 92.9418 70.366 90.9611 70.9336C89.0503 71.4785 87.0753 71.2322 85.036 70.1948Z"
        fill="url(#paint1_linear_115_6698)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_115_6698"
          x1="70.1522"
          y1="41.1488"
          x2="-18.9362"
          y2="95.8326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#201252" />
          <stop offset="0.942708" stopColor="#0F0530" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_115_6698"
          x1="103.066"
          y1="119.154"
          x2="58.5129"
          y2="60.0292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#d83c38" />
          <stop offset="1" stopColor="#F47FAE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
