import { isInIFrame } from "@/client/utils/isInIFrame";
import styled from "styled-components";

import IllustrationError from "../../assets/images/IllustrationError";
import IllustrationGeneral from "../../assets/images/IllustrationGeneral";

export type FeedbackProps = {
  "data-cy"?: string;
  headerText?: string;
  message?: string;
  type?: "Error";
  children?: JSX.Element | JSX.Element[];
  borderTop?: boolean;
};

const StyledDiv = styled.div<{ hasBorder: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
  border-top: ${({ hasBorder, theme }) =>
    !hasBorder ? "0px solid" : `1px solid ${theme.colors.divider.default}`};
`;

const StyledMessage = styled.span``;

const StyledRow = styled.div`
  /* margin: 1rem 0; */
  display: inline-flex;
  justify-content: center;
  gap: 8px;
`;

const StyledH3 = styled.h3`
  line-height: 0;
`;

export default function Feedback(props: FeedbackProps): JSX.Element {
  const { borderTop = true } = props;
  const inIFrame = isInIFrame();

  return (
    <StyledDiv hasBorder={borderTop && inIFrame} data-cy={props["data-cy"]}>
      {props.type == "Error" ? (
        <IllustrationError dataCy="illustration_error_icon" />
      ) : (
        <IllustrationGeneral data-cy="illustration_general_icon" />
      )}
      {props.headerText && <StyledH3>{props.headerText}</StyledH3>}
      {props.message && <StyledMessage>{props.message}</StyledMessage>}
      <StyledRow>{props.children}</StyledRow>
    </StyledDiv>
  );
}
